





























import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Login extends Vue {
  private get year() {
    return new Date().getFullYear();
  }
}
